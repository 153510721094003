export const SET_REGISTER_VALUE = "SET_REGISTER_VALUE";
export const setValueAction = (registerState: any) => {
  return {
    type: SET_REGISTER_VALUE,
    payload: {
      title: registerState.title,
      caption: registerState.caption,
      releasedate: registerState.releasedate,
      imageurl: registerState.imageurl,
      enTitle: registerState.enTitle,
      enCaption: registerState.enCaption,
      updateDate: registerState.updateDate,
      registDate: registerState.registDate,
      urlDigitoku: registerState.urlDigitoku,
      dateDigitoku: registerState.dateDigitoku,
      enUrlDigitoku: registerState.enUrlDigitoku,
      enDateDigitoku: registerState.enDateDigitoku,
    },
  };
};
