export const SET_INPUT_VALUE = 'SET_INPUT_VALUE'
export const setValueAction = (inputState: any) => {
    return {
        type: SET_INPUT_VALUE,
        payload: {
            inputSpotifyIdFlag: inputState.inputSpotifyIdFlag,
            inputAppleMusicIdFlag: inputState.inputAppleMusicIdFlag,
            inputOtherFlag: inputState.inputOtherFlag,
        }
    }
}   