const initialState = {
  apple: {
    applealbumid: "",
    applealbumname: "",
  },
  spotify: {
    spotifyalbumname: "",
    artistid: "",
    artistname: "",
  },
  register: {
    title: "",
    caption: "",
    releasedate: "",
    imageurl: "",
    enTitle: "",
    enCaption: "",
    updateDate: "",
    registDate: "",
    urlDigitoku: "",
    dateDigitoku: "",
    enUrlDigitoku: "",
    enDateDigitoku: "",
  },
  uniqueid: {
    flag: "register",
    uniqueid: "",
    username: "",
    preLocation: "",
  },
  reservecount: {
    apple_sum: "0",
    apple_yesterday: "0",
    spotify_sum: "0",
    spotify_yesterday: "0",
  },
  search: {
    artist: "",
    date: "",
    album: "",
    checkedflag: false,
    page: 1,
    clearbuttonflag: true,
  },
  show: {
    show: false,
  },
  sort: {
    dateSort: "descend",
    artistSort: "descend",
    albumSort: "descend",
    updateDateSort: "descend",
    registDateSort: "descend",
  },
  input: {
    inputSpotifyIdFlag: false,
    inputAppleMusicIdFlag: false,
    inputOtherFlag: false,
  },
  loading: {
    loadingFlag: false,
  },
};

export default initialState;
