export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const setValueAction = (SearchState: any) => {
    return {
        type: SET_SEARCH_VALUE,
        payload: {
            artist: SearchState.artist,
            date: SearchState.date,
            album: SearchState.album,
            checkedflag: SearchState.checkedflag,
            page: SearchState.page,
            clearbuttonflag: SearchState.clearbuttonflag,
        }
    }
}   