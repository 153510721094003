import * as Actions from './actions'
import initialState from '../store/initialState'

export const UniqueIdReducer = (state = initialState.uniqueid, action: any) => {
    switch (action.type) {
        case Actions.SET_UNIQUE_VALUE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}