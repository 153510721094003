import * as Actions from './actions'
import initialState from '../store/initialState'

export const InputReducer = (state = initialState.input, action: any) => {
    switch (action.type) {
        case Actions.SET_INPUT_VALUE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}