import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import { Provider } from 'react-redux'
import createStore from './redux/store/store'
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import 'antd/dist/antd.min.css';

Amplify.configure(config);

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
  },
  Storage: {
    AWS3: {
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
    }
  },

  API: {
    endpoints: [
      {
        name: "searchSchedule",
        endpoint: process.env.REACT_APP_SEARCH_SCHEDULE_ENDPOINT,
      },
      {
        name: "getPspaInfoById",
        endpoint: process.env.REACT_APP_PSPA_INFO_BY_ID_ENDPOINT,
      },
      {
        name: "appleSearch",
        endpoint: process.env.REACT_APP_APPLE_SEARCH_ENDPOINT,

      },
      {
        name: "spotifySearch",
        endpoint: process.env.REACT_APP_SPOTIFY_SEARCH_ENDPOINT,
      },
      {
        name: "register",
        endpoint: process.env.REACT_APP_REGISTER_ENDPOINT,
      },
    ],
  },
});

export const store = createStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={jaJP}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

