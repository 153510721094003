
import './table.css';
import { Card, Flex } from "@aws-amplify/ui-react";
import { Button, Table } from 'antd';
import { Link } from "react-router-dom";
import type { ColumnsType } from 'antd/lib/table';
import { useDispatch } from "react-redux";
import { setValueAction } from "../../../redux/apple/actions";
import { setValueAction as setFlagAction } from "../../../redux/input/actions";
import { LoadingOutlined } from '@ant-design/icons';

interface DataType {
  albumId: React.Key;
  show_link: string;
  artistName: string;
  albumName: string;
}

const Tables = (props) => {



  const dispatch = useDispatch();

  const dataSource = props.items;

  const setData = (record) => {
    dispatch(
      setValueAction({
        applealbumid: record.albumId,
        applealbumname: record.albumName,
      })
    );
    dispatch(setFlagAction({
      inputSpotifyIdFlag: true,
      inputAppleMusicIdFlag: true,
      inputOtherFlag: true,
    }))
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'show_link',
      render: (text, record, _) => <nav> <Button><Link to='/edit' onClick={() => { setData(record) }}>決定</Link></Button></nav>,
      width: 120,
      align: "center",
    },
    {
      title: 'アルバムID',
      dataIndex: 'albumId',
      key: 'albumId',
      width: 200,
    },
    {
      title: 'アーティスト名',
      dataIndex: 'artistName',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.artistName.localeCompare(b.artistName),
      key: 'artistName',
    },
    {
      title: 'アルバム名',
      dataIndex: 'albumName',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.albumName.localeCompare(b.albumName),
      key: 'albumName',
    },
  ];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const tableLoading = {
    spinning: props.loading,
    indicator: antIcon,
    tip: "loading..."
  }

  return (
    <Card >
      <Flex direction="row" justifyContent="flex-end">検索結果{props.items.length}件</Flex>
      <Table dataSource={dataSource} columns={columns} size="small" loading={tableLoading} />
    </Card>
  );
};

export default Tables;