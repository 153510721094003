import * as Actions from './actions'
import initialState from '../store/initialState'

export const SearchReducer = (state = initialState.search, action: any) => {
    switch (action.type) {
        case Actions.SET_SEARCH_VALUE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}