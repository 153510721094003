import { createStore as reduxCreateStor, combineReducers } from 'redux'
import { AppleReducer } from '../apple/reducers'
import { SpotifyReducer } from '../spotify/reducers'
import { RegisterReducer } from '../register/reducers'
import { UniqueIdReducer } from '../uniqueid/reducers'
import { ReservecountReducer } from '../reservecount/reducers'
import { SearchReducer } from '../search/reducers'
import { ShowReducer } from '../show/reducers'
import { SortReducer } from '../sort/reducers'
import { InputReducer } from '../input/reducers'
import { LoadingReducer } from '../loading/reducers';

export default function createStore() {
    return reduxCreateStor(combineReducers({
        apple: AppleReducer,
        spotify: SpotifyReducer,
        register: RegisterReducer,
        uniqueid: UniqueIdReducer,
        reservecount: ReservecountReducer,
        search: SearchReducer,
        show: ShowReducer,
        sort: SortReducer,
        input: InputReducer,
        loading: LoadingReducer,
    }))
}