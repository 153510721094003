import {
  AmplifyProvider,
  Flex,
  View,
  Card,
  Heading,
  Text,
  Button,
  TextField,
  Image,
  Divider,
} from "@aws-amplify/ui-react";
import React from "react";
import { Layout, Spin } from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setValueAction } from "../../redux/uniqueid/actions";
import { setValueAction as setFlagAction } from "../../redux/input/actions";
import { Content } from "antd/lib/layout/layout";
import { LoadingOutlined } from "@ant-design/icons";
import { setValueAction as setLoadingAction } from "../../redux/loading/actions";
import { ReplaceDate } from "../../utils/replaceDate";

export const Confirm = () => {
  const selector = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();

  let baseURL = "";
  if (process.env.REACT_APP_ENV === "STG") {
    baseURL = "https://stg-paps.grooveforce-jp.com/lp";
  } else {
    baseURL = "https://paps.grooveforce-jp.com/lp";
  }
  const openExternalBrowser = "&openExternalBrowser=1";
  const idLink = "?id=" + selector.uniqueid.uniqueid;

  const preLink = baseURL + idLink + openExternalBrowser;

  const urlCopyToClipboard = (text) => {
    const type = "text/plain";
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard.write(data).then(
      function () {
        /* success */
        alert("URLをクリップボードにコピーしました");
      },
      function () {
        /* failure */
        alert("URLのコピーに失敗しました");
      }
    );
  };
  const location = useLocation();

  console.log("selector in confirm");
  console.log(selector);
  console.log(location.pathname);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const toHome = () => {
    dispatch(
      setLoadingAction({
        loadingFlag: false,
      })
    );
  };

  return selector.loading.loadingFlag === false ? (
    <Layout>
      <Content style={{ background: "white" }}>
        <div
          style={{
            margin: "auto",
            width: "5%",
            padding: "20%",
          }}
        >
          <Spin
            tip="Loading"
            indicator={antIcon}
            style={{
              margin: "auto",
            }}
          />
        </div>
      </Content>
    </Layout>
  ) : (
    <AmplifyProvider>
      <Card className="App">
        {/* ヘッダー下:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <Flex direction="column" gap="1rem">
            <Heading level={5}>{"[内容確認画面]"}</Heading>
            <Text fontSize="13px">
              Pre-Save/Pre-Add登録システムの「内容確認ページ」です。登録済みのアルバム情報やランディングページ情報の確認を行います。
              <br />
              内容を修正する場合は、画面下部の『修正』ボタンを押してください。
            </Text>
          </Flex>
        </View>
        {/* ヘッダー下:end ---- */}

        {/* Apple/Spotify登録情報:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <View as="div" backgroundColor="#ccc" padding="0.3rem">
            <Heading level={6}>■ Spotify／Apple Music 登録情報</Heading>
          </View>

          <View as="div" padding="0.75rem">
            <Flex direction="column" gap="0.5rem">
              {/* 説明文 */}
              <Flex direction="column" gap="0rem" fontSize="13px">
                <Text>
                  下記の内容でPre-Save/Pre-Addを実施するアルバムの情報を登録しました。
                </Text>
              </Flex>

              {/* Spotify枠 */}
              <Divider orientation="horizontal" />
              <Heading>【Spotify】</Heading>
              <View as="div" paddingLeft="1rem">
                <Flex direction="row">
                  <Text width="6.5rem">アーティストID</Text>
                  <Text>{selector.spotify.artistid}</Text>
                </Flex>
                <Flex direction="row">
                  <Text width="6.5rem">アーティスト名</Text>
                  <Text>{selector.spotify.artistname}</Text>
                </Flex>
                <Flex direction="row">
                  <Text width="6.5rem">アルバム名</Text>
                  <Text>{selector.spotify.spotifyalbumname}</Text>
                </Flex>
                <Flex direction="row" fontWeight={800}>
                  <Text width="6.5rem">予約件数</Text>
                  <Text>
                    前日 {selector.reservecount.spotify_yesterday || "0"} 件
                  </Text>
                  <Text>/</Text>
                  <Text>
                    総数 {selector.reservecount.spotify_sum || "0"} 件
                  </Text>
                </Flex>
              </View>

              {/* Apple枠 */}
              <Divider orientation="horizontal" />
              <Heading>【AppleMusic】</Heading>
              <View as="div" paddingLeft="1rem">
                <Flex direction="row">
                  <Text width="6.5rem">アルバムID</Text>
                  <Text>{selector.apple.applealbumid}</Text>
                </Flex>
                <Flex direction="row">
                  <Text width="6.5rem">アルバム名</Text>
                  <Text>{selector.apple.applealbumname}</Text>
                </Flex>
                <Flex direction="row" fontWeight={800}>
                  <Text width="6.5rem">予約件数</Text>
                  <Text>
                    前日 {selector.reservecount.apple_yesterday || "0"} 件
                  </Text>
                  <Text>/</Text>
                  <Text>総数 {selector.reservecount.apple_sum || "0"} 件</Text>
                </Flex>
              </View>
            </Flex>
          </View>
        </View>
        {/* Apple/Spotify登録情報:end   ---- */}

        {/* システム登録情報:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <View as="div" backgroundColor="#ccc" padding="0.3rem">
            <Heading level={6}>■ ランディングページ登録情報</Heading>
          </View>

          <View as="div" padding="0.75rem">
            <Flex direction="column" gap="0.5rem">
              {/* 説明文 */}
              <Flex direction="column" gap="0rem" fontSize="13px">
                <Text>
                  下記の内容でランディングページの情報を登録しました。
                  <br />
                  予約を受け付ける場合には、Pre-Save/Pre-Add用リンクをコピーして公開してください。
                </Text>
              </Flex>

              {/* 区切り線 */}
              <Divider orientation="horizontal" />

              <Flex direction="row" alignItems="flex-start">
                <Text padding="0.5rem">Pre-Save/Pre-Add用リンク</Text>
                <TextField
                  width="40%"
                  label="url"
                  inputMode="text"
                  value={preLink}
                  labelHidden={true}
                  isReadOnly={true}
                  onChange={() => {}}
                ></TextField>
                <Button
                  id="button_copy_url"
                  name="button_copy_url"
                  width="10rem"
                  onClick={() => {
                    urlCopyToClipboard(preLink);
                  }}
                >
                  URLをコピー
                </Button>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">タイトル</Text>
                <Text>{selector.register.title}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">アルバム紹介メッセージ</Text>
                <Text>{selector.register.caption}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">海外用タイトル</Text>
                <Text>{selector.register.enTitle}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">海外用アルバム紹介メッセージ</Text>
                <Text>{selector.register.enCaption}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">リリース日</Text>
                <Text>
                  {selector.uniqueid.flag === "edit"
                    ? selector.uniqueid.preLocation === "home"
                      ? selector.register.releasedate
                      : ReplaceDate(selector.register.releasedate)
                    : ReplaceDate(selector.register.releasedate)}
                </Text>
              </Flex>
              {/* デジタル特典対応  ---- */}
              <Flex direction="row">
                <Text width="13rem">デジタル特典URL</Text>
                <Text>{selector.register.urlDigitoku}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">デジタル特典有効期限</Text>
                <Text>
                  {selector.uniqueid.flag === "edit"
                    ? selector.uniqueid.preLocation === "home"
                      ? selector.register.dateDigitoku
                      : ReplaceDate(selector.register.dateDigitoku)
                    : ReplaceDate(selector.register.dateDigitoku)}
                </Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">海外用デジタル特典URL</Text>
                <Text>{selector.register.enUrlDigitoku}</Text>
              </Flex>
              <Flex direction="row">
                <Text width="13rem">海外用デジタル特典有効期限</Text>
                <Text>
                  {selector.uniqueid.flag === "edit"
                    ? selector.uniqueid.preLocation === "home"
                      ? selector.register.enDateDigitoku
                      : ReplaceDate(selector.register.enDateDigitoku)
                    : ReplaceDate(selector.register.enDateDigitoku)}
                </Text>
              </Flex>
              <Text>アルバムイメージ</Text>
              <Image
                alt={""}
                src={selector.register.imageurl}
                height="600px"
                width="600px"
              ></Image>
            </Flex>
          </View>
        </View>
        {/* システム登録情報:end   ---- */}

        {/* ボタン */}
        <Divider orientation="horizontal" />
        <Flex direction="row" justifyContent="left" padding="1rem">
          <Button
            onClick={() => {
              dispatch(
                setValueAction({
                  flag: "edit",
                  username: selector.uniqueid.username,
                  uniqueid: selector.uniqueid.uniqueid,
                  preLocation: "confirm",
                })
              );
              dispatch(
                setFlagAction({
                  inputSpotifyIdFlag: true,
                  inputAppleMusicIdFlag: true,
                  inputOtherFlag: true,
                })
              );
              dispatch(
                setLoadingAction({
                  loadingFlag: false,
                })
              );
            }}
          >
            <Link to="/edit" style={{ textDecoration: "none", color: "black" }}>
              修正
            </Link>
          </Button>
          {selector.uniqueid.flag === "register" ||
          (selector.uniqueid.preLocation === "edit" &&
            location.pathname === "/confirm") ? (
            <Button>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "black" }}
                onClick={toHome}
              >
                TOPページへ
              </Link>
            </Button>
          ) : (
            <Button>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "black" }}
                onClick={toHome}
              >
                戻る
              </Link>
            </Button>
          )}
        </Flex>
      </Card>
    </AmplifyProvider>
  );
};
