export const ReplaceDate = (date: string): string => {
  if (date) {
    return (
      date.slice(0, 4) +
      "年" +
      date.slice(4, 6) +
      "月" +
      date.slice(6, 8) +
      "日"
    );
  }
  return "";
};
