export const SET_SPOTIFY_VALUE = 'SET_SPOTIFY_VALUE'
export const setValueAction = (spotifystate: any) => {
    return {
        type: SET_SPOTIFY_VALUE,
        payload: {
            spotifyalbumname: spotifystate.spotifyalbumname,
            artistid: spotifystate.artistid,
            artistname: spotifystate.artistname,
        }
    }
}   