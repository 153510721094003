
import './table.css';
import {
  Card,
  Flex
} from "@aws-amplify/ui-react";
import {
  Button,
  Table
} from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { Link } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import { setValueAction } from "../../../redux/spotify/actions";
import { setValueAction as setFlagAction } from "../../../redux/input/actions";
import { useDispatch } from "react-redux";

interface DataType {
  artistId: React.Key;
  show_link: string;
  artistName: string;
}

const Tables = (props) => {

  const dispatch = useDispatch();

  const dataSource = props.items;
  console.log(dataSource)

  const [artistNameSort, setArtistNameSort] = useState(props.items.sort.artistNameSort)

  const setData = (record) => {
    dispatch(
      setValueAction({
        artistid: record.artistId,
        artistname: record.artistName,
      }),
    );
    dispatch(setFlagAction({
      inputSpotifyIdFlag: true,
      inputAppleMusicIdFlag: true,
      inputOtherFlag: true,
    }))
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'show_link',
      render: (text, record, _) => <nav> <Button><Link to='/edit' onClick={() => { setData(record) }}>決定</Link></Button></nav>,
      width: 120,
      align: "center",
    },
    {
      title: 'アーティストID',
      dataIndex: 'artistId',
      key: 'artistId',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'アーティスト名',
      dataIndex: 'artistName',
      key: 'artistName',
      defaultSortOrder: props.items.sort.artistNameSort,
      sortDirections: props.items.sort.artistNameSort === 'descend' ? ['descend', 'ascend', 'descend'] : ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.artistName.localeCompare(b.artistName),
      ellipsis: true,
    },
  ];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const tableLoading = {
    spinning: props.loading,
    indicator: antIcon,
    tip: "loading..."
  }

  return (
    <Card >
      <Flex direction="row" justifyContent="flex-end">検索結果{props.items.length}件</Flex>
      <Table dataSource={dataSource} columns={columns} size="small" loading={tableLoading} />
    </Card>
  );
};

export default Tables;