import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  TextField,
  Text,
  Card,
  Heading,
  Divider,
  View,
} from "@aws-amplify/ui-react";
import { Col, Form, Input, Row, DatePicker, Modal } from "antd";
import { Link } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Storage, Auth } from "aws-amplify";
import { setValueAction as setRegister } from "../../redux/register/actions";
import { setValueAction as setApple } from "../../redux/apple/actions";
import { setValueAction as setSpotify } from "../../redux/spotify/actions";
import { setValueAction as setUniqueId } from "../../redux/uniqueid/actions";
import { setValueAction as setLoadingAction } from "../../redux/loading/actions";
import { setValueAction as setReservecount } from "../../redux/reservecount/actions";
import { setValueAction as setFlagAction } from "../../redux/input/actions";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import moment from "moment";
import { ReplaceDate } from "../../utils/replaceDate";

//

const REQUIRED_ITEM_MESSAGE = "未入力の項目があります";
const PATTERN_MESSAGE = "半角文字で入力してください";
const OVER_MAX_LENGTH_MESSAGE = "1000文字以内で入力してください";
const VALIDATE_URL_MESSAGE = "URLを入力してください";

const WIDTH_LABEL = "12rem";
const WIDTH_TEXTFIELD = "25rem";
const spotifyArtistIdPlaceholderDefault = "(例)3z8diLlUCkN1j9N9ZdnfBJ";
const spotifyArtistNamePlaceholderDefault = "(例)NiziU";
const spotifyAlbumNamePlaceholderDefault = "(例)CLAP CLAP";
const appleMusicAlbumIdPlaceholderDefault = "(例)1632415803";
const appleMusicAlbumNamePlaceholderDefault = "(例)CLAP CLAP";
const titlePlaceholderDefault = "(例)NiziU 先行配信「CLAP CLAP」";
const captionPlaceholderDefault =
  "(例)2022/7/20 Release! 3rd Single『CLAP CLAP』より M1「CLAP CLAP」7/13先行配信";
const enTitlePlaceholderDefault = '(例)NiziU Prior distribution "CLAP CLAP"';
const enCaptionPlaceholderDefault =
  '(例)2022/7/20 Release! 3rd Single "CLAP CLAP"';

const urlDigitokuDefault = "https://digitoku.sample/hoge/?param=fuga&foo=bar";
//

var uploadFile;
var formValues;
var imageUrl = "";
var selectValue;
var inputSpotifyAlbumName = "";

export const Edit = () => {
  const selector = useSelector((state: RootStateOrAny) => state);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  //
  const [form] = Form.useForm();
  const [editState, setEditState] = useState(false);
  selectValue = selector;
  console.log(selector);

  // state of placeholder
  const [spotifyArtistIdPlaceholder, setSpotifyArtistIdPlaceholder] = useState(
    spotifyArtistIdPlaceholderDefault
  );
  const [spotifyArtistNamePlaceholder, setSpotifyArtistNamePlaceholder] =
    useState(spotifyArtistNamePlaceholderDefault);
  const [spotifyAlbumNamePlaceholder, setSpotifyAlbumNamePlaceholder] =
    useState(spotifyAlbumNamePlaceholderDefault);
  const [appleMusicAlbumIdPlaceholder, setAppleMusicAlbumIdPlaceholder] =
    useState(appleMusicAlbumIdPlaceholderDefault);
  const [appleMusicAlbumNamePlaceholder, setAppleMusicAlbumNamePlaceholder] =
    useState(appleMusicAlbumNamePlaceholderDefault);
  const [titlePlaceholder, setTitlePlaceholder] = useState(
    titlePlaceholderDefault
  );
  const [captionPlaceholder, setCaptionPlaceholder] = useState(
    captionPlaceholderDefault
  );
  const [enTitlePlaceholder, setEnTitlePlaceholder] = useState(
    enTitlePlaceholderDefault
  );
  const [enCaptionPlaceholder, setEnCaptionPlaceholder] = useState(
    enCaptionPlaceholderDefault
  );

  const [urlDigitokuPlaceholder, setUrlDigitokuPlaceholder] =
    useState(urlDigitokuDefault);
  const [enUrlDigitokuPlaceholder, setEnUrlDigitokuPlaceholder] =
    useState(urlDigitokuDefault);

  const registerMusicIfo = () => {
    dispatch(
      setSpotify({
        artistid: formValues["spotifyArtistId"],
        artistname: formValues["spotifyArtistName"] ?? "",
        spotifyalbumname: formValues["spotifyAlbumName"],
      })
    );
    dispatch(
      setApple({
        applealbumid: formValues["appleMusicAlbumId"],
        applealbumname: formValues["appleMusicAlbumName"] ?? "",
      })
    );
  };

  const registerOtherInfo = () => {
    console.log(formValues);
    if (selector.uniqueid.flag === "register" || imageUrl !== "") {
      dispatch(
        setRegister({
          title: formValues["title"],
          caption: formValues["caption"] ?? "",
          enTitle: formValues["enTitle"],
          enCaption: formValues["enCaption"],
          releasedate: formValues["date-picker"].format("YYYYMMDD"),
          imageurl: imageUrl,
          urlDigitoku: formValues["urlDigitoku"],
          dateDigitoku: formValues["dateDigitoku"]
            ? formValues["dateDigitoku"].format("YYYYMMDD")
            : "",
          enUrlDigitoku: formValues["enUrlDigitoku"],
          enDateDigitoku: formValues["enDateDigitoku"]
            ? formValues["enDateDigitoku"].format("YYYYMMDD")
            : "",
        })
      );
    } else {
      dispatch(
        setRegister({
          title: formValues["title"],
          caption: formValues["caption"] ?? "",
          enTitle: formValues["enTitle"],
          enCaption: formValues["enCaption"],
          releasedate: formValues["date-picker"].format("YYYYMMDD"),
          imageurl: selector.register.imageUrl,
          urlDigitoku: formValues["urlDigitoku"],
          dateDigitoku: formValues["dateDigitoku"]
            ? formValues["dateDigitoku"].format("YYYYMMDD")
            : "",
          enUrlDigitoku: formValues["enUrlDigitoku"],
          enDateDigitoku: formValues["enDateDigitoku"]
            ? formValues["enDateDigitoku"].format("YYYYMMDD")
            : "",
        })
      );
      console.log(selector);
    }
  };

  const setFile = async (e) => {
    uploadFile = e.target.files[0];
  };

  const uploadImageFileToS3 = async (file) => {
    console.log(file);
    const extend = file.name.match(/[^.]+$/);
    console.log("extend");
    console.log(extend);

    const filename =
      formValues["appleMusicAlbumId"] +
      formValues["spotifyArtistId"] +
      "." +
      extend;

    console.log("file is");
    console.log(filename);

    Storage.configure({
      bucket: process.env.REACT_APP_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    });

    try {
      await Storage.put(filename, file, {
        contentType: "image/png; image/jpg; image/jpeg", // contentType is optional
      });
      await Storage.get(filename).then((url) => {
        console.log(url);
        console.log(url.split("?")[0]);
        imageUrl = url.split("?")[0];
        console.log(imageUrl);
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  const updateDB = async () => {
    var id: String = "";
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    if (imageUrl === "" && selector.register.imageurl !== undefined) {
      imageUrl = selector.register.imageurl;
    }
    const myInit = {
      // OPTIONAL
      headers: {
        Authorization: token,
        //"Content-Type": "",
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      mode: "cors",
      queryStringParameters: {
        appleAlbumId: selectValue.apple.applealbumid,
        appleAlbumName: selectValue.apple.applealbumname,
        caption: selectValue.register.caption,
        dateRelease: selectValue.register.releasedate,
        imageUrl: imageUrl,
        spotifyAlbumName: selectValue.spotify.spotifyalbumname,
        spotifyArtistName: selectValue.spotify.artistname,
        spotifyArtistId: selectValue.spotify.artistid,
        title: selectValue.register.title,
        userName: selectValue.uniqueid.username,
        en_title: selectValue.register.enTitle,
        en_caption: selectValue.register.enCaption,
        flag: selectValue.uniqueid.flag ?? "register",
        idPspaUnique: selectValue.uniqueid.uniqueid ?? "",

        //デジタル対応
        url_digitoku: selectValue.register.urlDigitoku,
        date_digitoku: selectValue.register.dateDigitoku,
        en_url_digitoku: selectValue.register.enUrlDigitoku,
        en_date_digitoku: selectValue.register.enDateDigitoku,
      },
    };
    await API.get("register", "/prd", myInit).then((response) => {
      id = response.data;
      dispatch(
        setUniqueId({
          flag: selector.uniqueid.flag ?? "register",
          uniqueid: id,
          username: selector.uniqueid.username,
          preLocation:
            selector.uniqueid.flag === "register" ? "register" : "edit",
        })
      );
      dispatch(
        setLoadingAction({
          loadingFlag: true,
        })
      );
    });
  };

  const initValue = () => {
    uploadFile = "";
    formValues = "";
    imageUrl = "";
    inputSpotifyAlbumName = "";
  };

  const back = () => {
    if (editState) {
      Modal.confirm({
        title: "confirm",
        content: "編集した内容は破棄されます、よろしいですか？",
        okText: "OK",
        cancelText: "キャンセル",
        onOk: () => {
          initValue();
          navigate("/");
        },
      });
    } else {
      initValue();
      navigate("/");
    }
  };

  const register = () => {
    Modal.confirm({
      title: "Confirm",
      content: "入力した内容で登録します、よろしいですか？",
      okText: "OK",
      cancelText: "キャンセル",
      onOk: async () => {
        registerMusicIfo();
        console.log("uploadFile");
        if (uploadFile !== undefined && uploadFile !== "") {
          await uploadImageFileToS3(uploadFile);
        }
        await registerOtherInfo();
        await updateDB();
        initValue();
        navigate("/confirm");
      },
    });
  };

  const onCheck = async () => {
    try {
      formValues = await form.validateFields();
      console.log("Success:", formValues);
      register();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  //
  const change = (values) => {
    setEditState(() => true);
    if ("spotifyAlbumName" in values) {
      inputSpotifyAlbumName = values["spotifyAlbumName"];
    }
  };

  // close the browser tab
  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue = "";
  };

  // spotify artist id
  useEffect(() => {
    form.setFieldsValue({
      spotifyArtistId: selector.spotify.artistid,
      spotifyArtistName: selector.spotify.artistname,
    });
    //

    if (selector.spotify.spotifyalbumname !== undefined) {
      form.setFieldsValue({
        spotifyAlbumName: selector.spotify.spotifyalbumname,
      });
    } else {
      if (inputSpotifyAlbumName !== "") {
        form.setFieldsValue({
          spotifyAlbumName: inputSpotifyAlbumName,
        });
      }
    }
  }, [selector.input.inputSpotifyIdFlag === true]);

  // apple music  album id
  useEffect(() => {
    form.setFieldsValue({
      appleMusicAlbumId: selector.apple.applealbumid,
      appleMusicAlbumName: selector.apple.applealbumname,
    });
  }, [selector.input.inputAppleMusicIdFlag === true]);

  //other
  useEffect(() => {
    form.setFieldsValue({
      title: selector.register.title,
      caption: selector.register.caption,
      enTitle: selector.register.enTitle,
      enCaption: selector.register.enCaption,
      urlDigitoku: selector.register.urlDigitoku,
      enUrlDigitoku: selector.register.enUrlDigitoku,
    });
    //
    if (
      selector.register.releasedate !== undefined &&
      selector.register.releasedate !== ""
    ) {
      if (selector.input.inputAppleMusicIdFlag) {
        form.setFieldsValue({
          "date-picker": moment(selector.register.releasedate, "YYYY/MM/DD"),
        });
      }
    }

    if (
      selector.register.imageurl !== undefined &&
      selector.register.imageurl !== ""
    ) {
      imageUrl = selector.register.imageurl;
    }

    //dateDigitoku
    if (selector.register.dateDigitoku) {
      form.setFieldsValue({
        dateDigitoku: moment(selector.register.dateDigitoku, "YYYY/MM/DD"),
      });
    }
    //enDateDigitoku
    if (selector.register.enDateDigitoku) {
      form.setFieldsValue({
        enDateDigitoku: moment(selector.register.enDateDigitoku, "YYYY/MM/DD"),
      });
    }
  }, [selector.input.inputOtherFlag === true]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
  }, []);

  const getData = async (id: string) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      // OPTIONAL
      headers: {
        Authorization: token,
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      mode: "cors",
      queryStringParameters: {
        // OPTIONAL
        //id: "5HLC9fMLoFX5wSst6we01120220211gYTa3U4Lk6",
        id: id,
      },
    };

    const response = await API.get("getPspaInfoById", "/prd", myInit);

    console.table(myInit);

    let record = response["data"];

    console.log(record);

    dispatch(
      setApple({
        applealbumid: record["id_album"],
        applealbumname: record["apple_name_album"],
      })
    );

    const date = record["date_release"];
    const digitokuDate = record["date_digitoku"];
    const enDigitokuDate = record["en_date_digitoku"];

    console.log("ById url image");
    console.log(record["url_image_s3"]);

    dispatch(
      setRegister({
        title: record["text_title"],
        caption: record["text_caption"],
        releasedate: ReplaceDate(date),
        imageurl: record["url_image_s3"].split("?")[0],
        enTitle: record["en_text_title"],
        enCaption: record["en_text_caption"],
        urlDigitoku: record["url_digitoku"],
        dateDigitoku: digitokuDate ? ReplaceDate(digitokuDate) : "",
        enUrlDigitoku: record["en_url_digitoku"],
        enDateDigitoku: enDigitokuDate ? ReplaceDate(enDigitokuDate) : "",
      })
    );

    dispatch(
      setSpotify({
        spotifyalbumname: record["spotify_name_album"],
        artistid: record["id_artist"],
        artistname: record["name_artist"],
      })
    );

    dispatch(
      setUniqueId({
        flag: "edit",
        uniqueid: record["id_pspa_unique"],
        username: selector.uniqueid.username,
        preLocation:
          selector.uniqueid.flag === "register" ? "register" : "edit",
      })
    );

    dispatch(
      setReservecount({
        apple_sum: record["apple_sum"],
        apple_yesterday: record["apple_yesterday"],
        spotify_sum: record["spotify_sum"],
        spotify_yesterday: record["spotify_yesterday"],
      })
    );

    dispatch(
      setFlagAction({
        inputSpotifyIdFlag: false,
        inputAppleMusicIdFlag: false,
        inputOtherFlag: false,
      })
    );

    console.log("====================selector===============");
    console.log(selector);

    dispatch(
      setLoadingAction({
        loadingFlag: true,
      })
    );
  };

  const backToConfirm = async (id: string) => {
    if (editState) {
      Modal.confirm({
        title: "confirm",
        content: "編集した内容は破棄されます、よろしいですか？",
        okText: "OK",
        cancelText: "キャンセル",
        onOk: async () => {
          initValue();
          await getData(id);
          navigate("/confirm");
        },
      });
    } else {
      initValue();
      await getData(id);
      navigate("/confirm");
    }
  };
  return (
    <View as="div">
      <Card>
        {/* ヘッダー下:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <Flex direction="column" gap="1rem">
            <Heading level={5}>{"[新規登録・修正]"}</Heading>
            <Text fontSize="13px">
              {
                "Pre-Save/Pre-Add登録システムの「新規登録・修正ページ」です。アルバム情報の新規登録、登録情報の修正を行います。"
              }
            </Text>
          </Flex>
        </View>
        {/* ヘッダー下:end ---- */}

        {/* 登録フォーム:begin */}
        <Form form={form} name="dynamic_rule" onValuesChange={change}>
          {/* Apple/Spotify登録情報:begin ---- */}
          <View as="div" paddingBottom="1rem">
            <View as="div" backgroundColor="#ccc" padding="0.3rem">
              <Heading level={6}>
                {"■ Spotify／Apple Music アルバム情報設定"}
              </Heading>
            </View>

            <View as="div" padding="0.75rem">
              <Flex direction="column" gap="0.5rem">
                {/* 説明文 */}
                <Flex direction="column" gap="0rem" fontSize="13px">
                  <Text>
                    {
                      "Pre-Save/Pre-Addを提供するアルバムの情報を入力してください。*印の項目は必須です。"
                    }
                  </Text>
                </Flex>

                {/* Spotify枠:begin */}
                <Divider orientation="horizontal" />
                <Heading>{"【Spotify】"}</Heading>
                <Flex direction="column" gap="0rem" paddingLeft="1rem">
                  <View
                    as="div"
                    gap="0rem"
                    fontSize="13px"
                    paddingBottom="1rem"
                  >
                    <Text>
                      {
                        "Pre-Saveを提供するアルバムのSpotifyアーティストIDと、アルバム名を入力してください。"
                      }
                      <br />
                      {
                        "アルバム名は、Spotifyのストア上の掲出名称と完全に同一な名称を入力してください。"
                      }
                      <br />
                      {
                        "SpotifyアーティストIDについては、『アーティスト検索』ボタンより検索することができます。"
                      }
                    </Text>
                  </View>

                  {/* SpotifyアーティストID:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"アーティストID"}</Text>
                        <Text color="red" paddingLeft="0.5rem">
                          <strong>{"*"}</strong>
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="spotifyArtistId"
                        rules={[
                          { required: true, message: REQUIRED_ITEM_MESSAGE },
                          {
                            pattern: /^[a-zA-z0-9]*$/,
                            message: PATTERN_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={spotifyArtistIdPlaceholder}
                          onFocus={() =>
                            setSpotifyArtistIdPlaceholder(() => "")
                          }
                          onBlur={() =>
                            setSpotifyArtistIdPlaceholder(
                              () => spotifyArtistIdPlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button width="11rem" size="small">
                        <Link
                          to="/spotifySearch"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {"アーティスト検索"}
                        </Link>
                      </Button>
                    </Col>
                  </Row>
                  {/* SpotifyアーティストID:end */}

                  {/* Spotifyアーティスト名:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"アーティスト名"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="spotifyArtistName"
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={spotifyArtistNamePlaceholder}
                          onFocus={() =>
                            setSpotifyArtistNamePlaceholder(() => "")
                          }
                          onBlur={() =>
                            setSpotifyArtistNamePlaceholder(
                              () => spotifyArtistNamePlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Spotifyアーティスト名:end */}

                  {/* Spotifyアルバム名:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"アルバム名"}</Text>
                        <Text color="red" paddingLeft="0.5rem">
                          <strong>{"*"}</strong>
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="spotifyAlbumName"
                        rules={[
                          { required: true, message: REQUIRED_ITEM_MESSAGE },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={spotifyAlbumNamePlaceholder}
                          onFocus={() =>
                            setSpotifyAlbumNamePlaceholder(() => "")
                          }
                          onBlur={() =>
                            setSpotifyAlbumNamePlaceholder(
                              () => spotifyAlbumNamePlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "Spotifyのストア上の掲出名称と一致させてください。Spotifyに納品する際、タイトルとサブタイトルを登録された場合には"
                        }
                        <br />
                        {
                          "Spotify側で自動的にサブタイトルへの半角スペースやカッコがつく仕組みも含め、Spotifyでの表記上との一致が必要となります。"
                        }
                      </Text>
                    </Col>
                  </Row>
                  {/* Spotifyアルバム名:end */}
                </Flex>
                {/* Spotify枠:end */}

                {/* Apple枠:begin */}
                <Divider orientation="horizontal" />
                <Heading>{"【AppleMusic】"}</Heading>
                <Flex direction="column" gap="0rem" paddingLeft="1rem">
                  <View
                    as="div"
                    gap="0rem"
                    fontSize="13px"
                    paddingBottom="1rem"
                  >
                    <Text>
                      {
                        "Pre-Addを提供するアルバムのアルバムIDを入力してください。"
                      }
                      <br />
                      {
                        "アルバムIDを直接入力するか、または『アルバム検索』ボタンより検索することができます。"
                      }
                    </Text>
                  </View>

                  {/* AppleアルバムID:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"アルバムID"}</Text>
                        <Text color="red" paddingLeft="0.5rem">
                          <strong>{"*"}</strong>
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="appleMusicAlbumId"
                        rules={[
                          {
                            required: true,
                            message: REQUIRED_ITEM_MESSAGE,
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: "半角数字で入力してください",
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={appleMusicAlbumIdPlaceholder}
                          onFocus={() =>
                            setAppleMusicAlbumIdPlaceholder(() => "")
                          }
                          onBlur={() =>
                            setAppleMusicAlbumIdPlaceholder(
                              () => appleMusicAlbumIdPlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button width="11rem" size="small">
                        <Link
                          to="/appleSearch"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          {"アルバム検索"}
                        </Link>
                      </Button>
                    </Col>
                  </Row>
                  {/* AppleアルバムID:end */}

                  {/* Appleアルバム名:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"アルバム名"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="appleMusicAlbumName"
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={appleMusicAlbumNamePlaceholder}
                          onFocus={() =>
                            setAppleMusicAlbumNamePlaceholder(() => "")
                          }
                          onBlur={() =>
                            setAppleMusicAlbumNamePlaceholder(
                              () => appleMusicAlbumNamePlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Appleアルバム名:end */}
                </Flex>
                {/* Apple枠:end */}
              </Flex>
            </View>
          </View>
          {/* Apple/Spotify登録情報:end   ---- */}

          {/* ランディングページ登録情報:begin ---- */}
          <View as="div" paddingBottom="1rem">
            <View as="div" backgroundColor="#ccc" padding="0.3rem">
              <Heading level={6}>{"■ ランディングページ情報設定"}</Heading>
            </View>

            <View as="div" padding="0.75rem">
              <Flex direction="column" gap="0.5rem">
                {/* 説明文 */}
                <Flex direction="column" gap="0rem" fontSize="13px">
                  <Text>
                    {
                      "Pre-Save/Pre-Addのランディングページに表示する情報を入力してください。*印の項目は必須です。"
                    }
                  </Text>
                </Flex>

                {/* 登録情報枠:begin */}
                <Divider orientation="horizontal" />
                <Flex
                  direction="column"
                  gap="0rem"
                  paddingLeft="1rem"
                  paddingTop="1rem"
                >
                  {/* タイトル:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"タイトル"}</Text>
                        <Text color="red" paddingLeft="0.5rem">
                          <strong>{"*"}</strong>
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="title"
                        rules={[
                          { required: true, message: REQUIRED_ITEM_MESSAGE },
                          {
                            type: "string",
                            max: 1000,
                            message: OVER_MAX_LENGTH_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={titlePlaceholder}
                          onFocus={() => setTitlePlaceholder(() => "")}
                          onBlur={() =>
                            setTitlePlaceholder(() => titlePlaceholderDefault)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "ランディングページに表示するタイトルを1,000文字以内で入力してください。"
                        }
                        <br />
                        {"全角15文字が1行での表示目安となります。"}
                      </Text>
                    </Col>
                  </Row>
                  {/* タイトル:end */}

                  {/* アルバム紹介メッセージ:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"アルバム紹介メッセージ"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="caption"
                        rules={[
                          {
                            type: "string",
                            max: 1000,
                            message: OVER_MAX_LENGTH_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={captionPlaceholder}
                          onFocus={() => setCaptionPlaceholder(() => "")}
                          onBlur={() =>
                            setCaptionPlaceholder(
                              () => captionPlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "ランディングページに表示するアルバム紹介メッセージを1,000文字以内で入力してください。"
                        }
                        <br />
                        {"全角35文字が1行での表示目安となります。"}
                      </Text>
                    </Col>
                  </Row>
                  {/* アルバム紹介メッセージ:end */}

                  {/*　海外タイトル:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"海外向けタイトル（英語）"}</Text>
                        <Text color="red" paddingLeft="0.5rem"></Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="enTitle"
                        rules={[
                          {
                            type: "string",
                            max: 1000,
                            message: OVER_MAX_LENGTH_MESSAGE,
                          },
                          {
                            pattern: /^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/,
                            message: PATTERN_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={enTitlePlaceholder}
                          onFocus={() => setEnTitlePlaceholder(() => "")}
                          onBlur={() =>
                            setEnTitlePlaceholder(
                              () => enTitlePlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px">
                        {"海外向けのランディングページに表示するタイトル（英語）を1,000文字以内で入力してください。"}
                        <br />
                        {"半角25文字が1行での表示目安となります。"}
                      </Text>
                      <Text fontSize="11px" color="red" paddingBottom="1rem">{"未入力の場合は海外向けランディングページは表示されません。"}</Text>
                    </Col>
                  </Row>

                  {/* 　海外タイトル:end */}

                  {/* 　海外アルバム紹介メッセージ:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"海外向けアルバム紹介メッセージ（英語）"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="enCaption"
                        rules={[
                          {
                            type: "string",
                            max: 1000,
                            message: OVER_MAX_LENGTH_MESSAGE,
                          },
                          {
                            pattern: /^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/,
                            message: PATTERN_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={enCaptionPlaceholder}
                          onFocus={() => setEnCaptionPlaceholder(() => "")}
                          onBlur={() =>
                            setEnCaptionPlaceholder(
                              () => enCaptionPlaceholderDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "海外向けのランディングページに表示するアルバム紹介メッセージ（英語）を1,000文字以内で入力してください。"
                        }
                        <br />
                        {"半角65文字が1行での表示目安となります。"}
                      </Text>
                    </Col>
                  </Row>

                  {/* 　海外アルバム紹介メッセージ:end */}

                  {/* アルバムイメージ:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"アルバムイメージ"}</Text>
                    </Col>
                    <Col>
                      <TextField
                        label="image"
                        labelHidden={true}
                        type="file"
                        size="small"
                        variation="quiet"
                        onChange={setFile}
                        style={{ width: WIDTH_TEXTFIELD }}
                        descriptiveText="ランディングページに表示する楽曲イメージを指定してください"
                      />
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "ランディングページに表示するアルバムイメージ(画像ファイル)を指定してください。"
                        }
                        <br />
                        {"（形式：JPG／PNG）"}
                      </Text>
                    </Col>
                  </Row>
                  {/* アルバムイメージ:end */}

                  <br />

                  {/* リリース日:begin */}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"リリース日"}</Text>
                        <Text color="red" paddingLeft="0.5rem">
                          <strong>{"*"}</strong>
                        </Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="date-picker"
                        rules={[
                          { required: true, message: REQUIRED_ITEM_MESSAGE },
                        ]}
                      >
                        <DatePicker format={"YYYY/MM/DD"} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px">
                        {
                          "Pre-Save/Pre-Addを提供するアルバムのリリース（予定）日を入力してください。"
                        }
                        <br />
                        {
                          "ここで設定された日以降にユーザーのライブラリに登録されます。登録される時刻は、Spotify／ Apple Musicでアルバムが公開される時間に依存します。"
                        }
                      </Text>
                    </Col>
                  </Row>
                  {/* リリース日:end */}

                  {/* デジタル特典URL:start*/}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"デジタル特典URL"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="urlDigitoku"
                        rules={[
                          {
                            type: "url",
                            message: VALIDATE_URL_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={urlDigitokuPlaceholder}
                          onFocus={() => setUrlDigitokuPlaceholder(() => "")}
                          onBlur={() =>
                            setUrlDigitokuPlaceholder(() => urlDigitokuDefault)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "国内用予約ページでの予約完了時にリダイレクトするデジタル特典のURLを入力してください。"
                        }
                        <br />
                        {
                          "URLの入力が省略された場合はリダイレクト処理は行われません。"
                        }
                      </Text>
                    </Col>
                  </Row>

                  {/* デジタル特典URL:end*/}

                  {/* デジタル特典有効期限:start*/}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"デジタル特典有効期限"}</Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="dateDigitoku"
                        rules={[{ required: false }]}
                      >
                        <DatePicker format={"YYYY/MM/DD"} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        minWidth: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                      }}
                    >
                      <Text style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                        {"23:59（日本時間）"}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontSize="11px">
                        {
                          "デジタル特典URLへのリダイレクトを行う日付の期限を入力してください。"
                        }
                        <br />
                        {
                          "期限の入力が省略された場合は、URLを入力してもリダイレクト処理は行われません。"
                        }
                      </Text>
                    </Col>
                  </Row>
                  {/* デジタル特典有効期限:end*/}

                  {/* 海外デジタル特典URL:start*/}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Text>{"海外用デジタル特典URL"}</Text>
                    </Col>
                    <Col>
                      <Form.Item
                        name="enUrlDigitoku"
                        rules={[
                          {
                            type: "url",
                            message: VALIDATE_URL_MESSAGE,
                          },
                        ]}
                        style={{ width: WIDTH_TEXTFIELD }}
                      >
                        <Input
                          placeholder={enUrlDigitokuPlaceholder}
                          onFocus={() => setEnUrlDigitokuPlaceholder(() => "")}
                          onBlur={() =>
                            setEnUrlDigitokuPlaceholder(
                              () => urlDigitokuDefault
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Text fontSize="11px" paddingBottom="1rem">
                        {
                          "海外用予約ページでの予約完了時にリダイレクトするデジタル特典のURLを入力してください。"
                        }
                        <br />
                        {
                          "URLの入力が省略された場合はリダイレクト処理は行われません。"
                        }
                      </Text>
                    </Col>
                  </Row>

                  {/* 海外デジタル特典URL:end*/}

                  {/* 海外デジタル有効期限:start*/}
                  <Row gutter={8}>
                    <Col style={{ width: WIDTH_LABEL }}>
                      <Row>
                        <Text>{"海外用デジタル特典有効期限"}</Text>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item
                        name="enDateDigitoku"
                        rules={[{ required: false }]}
                      >
                        <DatePicker format={"YYYY/MM/DD"} />
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        minWidth: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "25px",
                      }}
                    >
                      <Text style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                        {"23:59（日本時間）"}
                      </Text>
                    </Col>
                    <Col>
                      <Text fontSize="11px">
                        {
                          "海外用デジタル特典URLへのリダイレクトを行う日付の期限を入力してください。"
                        }
                        <br />
                        {
                          "期限の入力が省略された場合は、URLを入力してもリダイレクト処理は行われません。"
                        }
                      </Text>
                    </Col>
                  </Row>
                  {/* 海外デジタル有効期限:end*/}
                </Flex>
                {/* 登録情報枠:end */}
              </Flex>
            </View>
          </View>
          {/* ランディングページ登録情報:end   ---- */}

          {/* ボタン */}

          <Divider orientation="horizontal" />

          <Flex direction="row" justifyContent="left" padding="1rem">
            <Button width="10rem" onClick={onCheck}>
              {"登録"}
            </Button>
            {selector.uniqueid.flag === "edit" ? (
              <Button
                width="10rem"
                onClick={() => backToConfirm(selector.uniqueid.uniqueid)}
              >
                {"前の画面へ戻る"}
              </Button>
            ) : (
              // <Button width="10rem">
              //   <Link to="/confirm" onClick={() => { getData(selector.uniqueid.uniqueid) }} style={{ textDecoration: 'none', color: 'black' }} >{'前の画面へ戻る'}</Link></Button>
              <Button width="10rem" onClick={back}>
                {"前の画面へ戻る"}
              </Button>
            )}
          </Flex>
        </Form>
        {/* 登録フォーム:end */}
      </Card>
    </View>
  );
};
