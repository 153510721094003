import React, { useState, useEffect, FC } from "react";
import "./table.css";
import { Link } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setValueAction as setApple } from "../../redux/apple/actions";
import { setValueAction as setSpotify } from "../../redux/spotify/actions";
import { setValueAction as setRegister } from "../../redux/register/actions";
import { setValueAction as setUniqueId } from "../../redux/uniqueid/actions";
import { setValueAction as setReservecount } from "../../redux/reservecount/actions";
import { setValueAction as setFlagAction } from "../../redux/input/actions";
import { setValueAction as setLoadingAction } from "../../redux/loading/actions";
import { setValueAction as setSearchAction } from "../../redux/search/actions";
import { setValueAction as setSort } from "../../redux/sort/actions";
import { API, Auth } from "aws-amplify";
import { Button, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/lib/table";
import moment from "moment";
import type { PaginationProps } from "antd";
import { useNavigate } from "react-router-dom";
import { ReplaceDate } from "../../utils/replaceDate";

interface DataType {
  id_pspa_unique: React.Key;
  show_link: string;
  release_date: any;
  name_artist: string;
  name_album: string;
  update_date: string;
  regist_date: string;
}

interface IProps {
  items: any[];
  artist: string;
  album: string;
  updateDate: String;
  releaseDate: String;
  registDate: String;
  checkedOtherUser: boolean;
  isClearButtonDisabled: boolean;
}

export const Tables: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const selector = useSelector((state: RootStateOrAny) => state);
  const [currentRows, setCurrentRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(selector.search.page);

  let navigate = useNavigate();

  console.log("====================selector===============");
  console.log(selector);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    _,
    pageSize
  ) => {
    setPageSize(pageSize);
  };

  const getData = async (id) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      // OPTIONAL
      headers: {
        Authorization: token,
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      mode: "cors",
      queryStringParameters: {
        // OPTIONAL
        //id: "5HLC9fMLoFX5wSst6we01120220211gYTa3U4Lk6",
        id: id,
      },
    };

    const response = await API.get("getPspaInfoById", "/prd", myInit);

    console.table(myInit);

    let record = response["data"];

    console.log(record);

    dispatch(
      setApple({
        applealbumid: record["id_album"],
        applealbumname: record["apple_name_album"],
      })
    );

    const date = record["date_release"];
    const digitokuDate = record["date_digitoku"];
    const enDigitokuDate = record["en_date_digitoku"];
    console.log("ById url image");
    console.log(record["url_image_s3"]);

    dispatch(
      setRegister({
        title: record["text_title"],
        caption: record["text_caption"],
        releasedate: ReplaceDate(date),
        imageurl: record["url_image_s3"].split("?")[0],
        enTitle: record["en_text_title"],
        enCaption: record["en_text_caption"],
        urlDigitoku: record["url_digitoku"],
        dateDigitoku: digitokuDate ? ReplaceDate(digitokuDate) : "",
        enUrlDigitoku: record["en_url_digitoku"],
        enDateDigitoku: enDigitokuDate ? ReplaceDate(enDigitokuDate) : "",
      })
    );

    dispatch(
      setSpotify({
        spotifyalbumname: record["spotify_name_album"],
        artistid: record["id_artist"],
        artistname: record["name_artist"],
      })
    );

    dispatch(
      setUniqueId({
        flag: "edit",
        uniqueid: record["id_pspa_unique"],
        username: selector.uniqueid.username,
        preLocation: "home",
      })
    );

    dispatch(
      setReservecount({
        apple_sum: record["apple_sum"],
        apple_yesterday: record["apple_yesterday"],
        spotify_sum: record["spotify_sum"],
        spotify_yesterday: record["spotify_yesterday"],
      })
    );

    dispatch(
      setFlagAction({
        inputSpotifyIdFlag: false,
        inputAppleMusicIdFlag: false,
        inputOtherFlag: false,
      })
    );

    console.log(props.releaseDate);

    dispatch(
      setSearchAction({
        artist: props.artist ?? "",
        date: props.releaseDate ?? "",
        album: props.album ?? "",
        checkedflag: props.checkedOtherUser ?? false,
        page: page,
        clearbuttonflag: props.isClearButtonDisabled ?? true,
      })
    );

    console.log("====================selector===============");
    console.log(selector);

    dispatch(
      setLoadingAction({
        loadingFlag: true,
      })
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "show_link",
      render: (id) => (
        <nav>
          {" "}
          <Button>
            <Link
              to="/confirm"
              onClick={() => {
                getData(id);
              }}
            >
              内容確認
            </Link>
          </Button>
        </nav>
      ),
      width: 120,
      align: "center",
    },
    {
      title: "リリース日",
      dataIndex: "release_date",
      // sorter: {
      //   compare: (a, b) => {
      //     var a_date;
      //     var b_date;
      //     a_date = a.release_date.slice(0, 4) + "-" + a.release_date.slice(5, 7) + "-" + a.release_date.slice(8, 10)
      //     b_date = b.release_date.slice(0, 4) + "-" + b.release_date.slice(5, 7) + "-" + b.release_date.slice(8, 10)
      //     return moment(a_date).unix() - moment(b_date).unix();
      //   },
      //   // multiple: 3
      // },
      sorter: (a, b) => {
        var a_date;
        var b_date;
        a_date =
          a.release_date.slice(0, 4) +
          "-" +
          a.release_date.slice(5, 7) +
          "-" +
          a.release_date.slice(8, 10);
        b_date =
          b.release_date.slice(0, 4) +
          "-" +
          b.release_date.slice(5, 7) +
          "-" +
          b.release_date.slice(8, 10);
        return moment(a_date).unix() - moment(b_date).unix();
      },

      defaultSortOrder: selector.sort.dateSort,
      sortDirections:
        selector.sort.dateSort === "descend"
          ? ["descend", "ascend", "descend"]
          : ["ascend", "descend", "ascend"],
      width: 150,
      align: "center",
    },
    {
      title: "アーティスト名",
      dataIndex: "name_artist",
      defaultSortOrder: selector.sort.artistSort,
      sortDirections:
        selector.sort.artistSort === "descend"
          ? ["descend", "ascend", "descend"]
          : ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.name_artist.localeCompare(b.name_artist),
      // sorter: {
      //   compare: (a, b) => a.name_artist.localeCompare(b.name_artist),
      //   multiple: 2,
      // },

      ellipsis: true,
    },
    {
      title: "アルバム名",
      dataIndex: "name_album",
      defaultSortOrder: selector.sort.albumSort,
      sortDirections:
        selector.sort.albumSort === "descend"
          ? ["descend", "ascend", "descend"]
          : ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.name_album.localeCompare(b.name_album),
      // sorter: {
      //   compare: (a, b) => a.name_album.localeCompare(b.name_album),
      //   multiple: 1,
      // },

      ellipsis: true,
    },
    {
      title: "最終更新日時",
      dataIndex: "update_date",
      defaultSortOrder: selector.sort.updateDateSort,
      sortDirections:
        selector.sort.updateDateSort === "descend"
          ? ["descend", "ascend", "descend"]
          : ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.update_date.localeCompare(b.update_date),
      ellipsis: true,
    },
    {
      title: "登録日時",
      dataIndex: "regist_date",
      defaultSortOrder: selector.sort.registDateSort,
      sortDirections:
        selector.sort.registDateSort === "descend"
          ? ["descend", "ascend", "descend"]
          : ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.regist_date.localeCompare(b.regist_date),
      ellipsis: true,
    },
  ];

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    sorter,
    extra: any
  ) => {
    console.log("pagination, sorter , extra ", pagination, sorter, extra);
    console.log(pagination.current);
    console.log(extra.order);
    setPage(pagination.current);

    //  multiple
    var dataOrder = "";
    var artistOrder = "";
    var albumOrder = "";
    extra.forEach((element) => {
      if (element.field === "name_album") {
        albumOrder = element.order;
      } else if (element.field === "name_artist") {
        artistOrder = element.order;
      } else if (element.field === "release_date") {
        dataOrder = element.order;
      }
    });
    //setArtistSort(extra.order)
    dispatch(
      setSort({
        dateSort: dataOrder,
        artistSort: artistOrder,
        albumSort: albumOrder,
      })
    );
  };

  useEffect(() => {
    var tempRows = [];
    props.items.map((item) => {
      var rowsMap = {};
      rowsMap["id_pspa_unique"] = item.id_pspa_unique;
      rowsMap["release_date"] =
        item.date_release.slice(0, 4) +
        "年" +
        item.date_release.slice(4, 6) +
        "月" +
        item.date_release.slice(6, 8) +
        "日";
      rowsMap["update_date"] =
        item.time_update.slice(0, 4) +
        "年" +
        item.time_update.slice(4, 6) +
        "月" +
        item.time_update.slice(6, 8) +
        "日 " +
        item.time_update.slice(8, 10) +
        "時" +
        item.time_update.slice(10, 12) +
        "分";
      rowsMap["regist_date"] =
        item.time_register.slice(0, 4) +
        "年" +
        item.time_register.slice(4, 6) +
        "月" +
        item.time_register.slice(6, 8) +
        "日 " +
        item.time_register.slice(8, 10) +
        "時" +
        item.time_register.slice(10, 12) +
        "分";
      rowsMap["name_artist"] = item.name_artist;
      rowsMap["name_album"] = item.name_album;
      rowsMap["show_link"] = item.id_pspa_unique;
      tempRows.push(rowsMap);
    });
    setCurrentRows(tempRows);
    setTotal(props.items.length);
  }, [props.items]);

  return (
    <Table
      rowKey={(recode) => recode.id_pspa_unique}
      style={{
        borderWidth: "1px",
        borderColor: "red",
      }}
      columns={columns}
      dataSource={currentRows}
      pagination={{
        defaultCurrent: page,
        onShowSizeChange: onShowSizeChange,
        showSizeChanger: true,
        pageSize: pageSize,
        pageSizeOptions: [10, 20, 50],
        position: ["bottomCenter"],
        total: total,
        size: "default",
      }}
      size="small"
      onChange={onChange}
      bordered
    />
  );
};
