import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import {
  AmplifyProvider,
  View,
  Button,
  Flex,
  TextField,
  Text,
  Heading,
  Divider,
  Card,
} from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import Tables from "./table/table";
import { useSelector } from "react-redux";

export const SpotifySearch = () => {
  const [name, setName] = useState("");
  const [albums, setAlbums] = useState([]);
  const [artistName, setArtistName] = useState("");
  const [artistId, setArtistId] = useState("");
  const [itemLength, setItemLength] = useState(1);
  const [loading, setLoading] = useState(false)
  const buttonControl = name.length > 0;
  const selector = useSelector((state) => state);

  console.log(selector);

  const changeName = (e) => setName(e.currentTarget.value);
  const fetchSpotifyAlbumData = async () => {
    setLoading(true)
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const requestInfo = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: {
        // OPTIONAL
        spotifyArtistName: name,
      },
    };

    await API.get("spotifySearch", "/prd", requestInfo)
      .then((response) => {
        if (response.length === 0) {
          setItemLength(0)
        } else {
          setItemLength(1)
          const recode = response;
          setAlbums(recode);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    setLoading(false)
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      fetchSpotifyAlbumData()
      e.preventDefault();
    }
  };

  // close the browser tab  
  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () => window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
  }, []);

  useEffect(() => {
    setAlbums(albums);
  }, [albums]);

  return (
    <AmplifyProvider >
      <Card>

        {/* ヘッダー下:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <Flex direction="column" gap="1rem">
            <Heading level={5}>{"[SpotifyアーティストID検索]"}</Heading>
            <Text fontSize="13px">
              {'Pre-Save/Pre-Add登録システムの「SpotifyアーティストID検索ページ」です。アーティスト名でSpotifyに登録されたアーティストIDを検索します。'}
              <br />
              {'前の画面に戻る場合には『戻る』ボタンを押してください。'}
            </Text>
          </Flex>
        </View>
        {/* ヘッダー下:end ---- */}

        {/* 検索フォーム:begin ---- */}
        <View as="div" paddingBottom="1rem">
          <View as="div" backgroundColor='#ccc' padding="0.3rem">
            <Heading level={6}>{'■SpotifyアーティストID検索'}</Heading>
          </View>

          <View as="div" padding="0.75rem">
            <Flex direction="column" gap="1rem">
              {/* 説明文 */}
              <Flex direction="column" gap="0rem" fontSize="13px">
                <Text>{'アーティスト名を入力して『検索』ボタンを押してください。'}</Text>
              </Flex>

              {/* 区切り線 */}
              <Divider orientation="horizontal" />

              {/* インプット */}
              <Flex as="form" direction="row" >
                <Text width="8rem" padding="0.5rem">{'アーティスト名'}</Text>
                <TextField
                  as="input"
                  label=""
                  onKeyDown={handleKeyDown}
                  labelHidden={true}
                  isRequired={true}
                  onChange={changeName}
                  size="small"
                  style={{ width: "25rem", height: "2.5rem" }}
                />
                <Button
                  width="10rem"
                  height="2.5rem"
                  disabled={!buttonControl}
                  onClick={fetchSpotifyAlbumData}>
                  {'検索'}
                </Button>
              </Flex>
            </Flex>
          </View>
        </View>
        {/* 検索フォーム:end ---- */}

        {/* 検索結果:begin ---- */}
        <View as="div" paddingBottom="1rem">

          <View as="div" backgroundColor='#ccc' padding="0.3rem">
            <Heading level={6}>{'■SpotifyアーティストID一覧'}</Heading>
          </View>

          <View as="div" padding="0.75rem">
            <Flex direction="column" gap="1rem">

              {/* 説明文 */}
              <Flex direction="column" gap="0rem" fontSize="13px">
                <Text>
                  {'表示された一覧からお探しのアーティストIDの左にある『決定』ボタンを押してください。'}
                  <br />{'登録画面にアーティストIDが自動的に入力されます。'}
                </Text>
              </Flex>

              <Divider orientation="horizontal" />
              <React.Fragment>
                <Tables items={albums} loading={loading} />
              </React.Fragment>

            </Flex>
          </View>

        </View>
        {/* 検索結果:end   ---- */}

        {/* 戻るボタン */}
        <Divider orientation="horizontal" />
        <Flex direction="row" justifyContent="left" padding="1rem">
          <Button>
            <Link to="/edit" style={{ textDecoration: 'none', color: 'black' }} >{'戻る'}</Link>
          </Button>
        </Flex>

      </Card>
    </AmplifyProvider >
  );
};
