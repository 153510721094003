import React, { useEffect } from "react";
import { useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { Auth, API } from "aws-amplify";
import { Link } from "react-router-dom";
import { Tables } from "./components/table/table";
import { setValueAction as setApple } from "./redux/apple/actions";
import { setValueAction as setSpotify } from "./redux/spotify/actions";
import { setValueAction as setRegister } from "./redux/register/actions";
import { setValueAction as setUniqueid } from "./redux/uniqueid/actions";
import { setValueAction as setReservecount } from "./redux/reservecount/actions";
import { setValueAction as setFlagAction } from "./redux/input/actions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Flex,
  Text,
  Card,
  Heading,
  View,
  Divider,
  TextField,
  CheckboxField,
} from "@aws-amplify/ui-react";


export const Home = (props) => {

  const dispatch = useDispatch();
  const selector = useSelector((state: RootStateOrAny) => state);

  const reset = () => {
    dispatch(
      setApple({
        applealbumid: "",
        applealbumname: "",
      })
    );

    dispatch(
      setRegister({
        title: "",
        caption: "",
        releasedate: "",
        imageurl: "",
        enTitle: "",
        enCaption: "",
      })
    );

    dispatch(
      setSpotify({
        spotifyalbumname: "",
        artistid: "",
        artistname: "",
      })
    );

    dispatch(
      setReservecount({
        apple_sum: "",
        apple_yesterday: "",
        spotify_sum: "",
        spotify_yesterday: "",
      })
    );


    dispatch(
      setReservecount({
        apple_sum: "",
        apple_yesterday: "",
        spotify_sum: "",
        spotify_yesterday: "",
      })
    );

    dispatch(setFlagAction({
      inputSpotifyIdFlag: false,
      inputAppleMusicIdFlag: false,
      inputOtherFlag: false,
    }));
  };

  const [releaseDate, setReleaseDate] = useState(selector.search.date);
  const [artist, setArtist] = useState(selector.search.artist);
  const [album, setAlbum] = useState(selector.search.album);
  const [updateDate, setUpdateDate] = useState("");
  const [registDate, setRegistDate] = useState("");

  // 他ユーザー検索のチェック制御
  const [checkedOtherUser, setCheckedOtherUser] = useState(selector.search.checkedflag);

  // 検索条件クリアボタンの活性制御
  const [isClearButtonDisabled, setClearButtonDisabled] = useState(selector.search.clearbuttonflag);
  const [items, setItems] = useState([]);
  const [username, setUsername] = useState(props.user);


  const handleReleaseDateChange = (e) => {
    console.log(e)
    console.log(e.target.value)
    setReleaseDate(() => e.target.value);

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };

  const handleArtistChange = (e) => {
    setArtist(() => e.target.value);

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };

  const handleAlbumChange = (e) => {
    setAlbum(() => e.target.value);

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };

  const handleUpdateDateChange = (e) => {
    setUpdateDate(() => e.target.value);

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };

  const handleRegistDateChange = (e) => {
    setRegistDate(() => e.target.value);

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };


  const handleEditorChange = (e) => {
    setCheckedOtherUser(() => e.target.checked)

    if (e.target.checked) {
      setUsername(function () {
        return "";
      });
    } else {
      Auth.currentAuthenticatedUser().then(function (data) {
        setUsername(function () {
          return data.username;
        });
      });
    }

    setClearButtonDisabled(false);  // 検索条件クリアボタン：活性
  };

  useEffect(() => {
    setItems(items);
    Auth.currentAuthenticatedUser().then(function (data) {
      dispatch(
        setUniqueid({
          flag: "register",
          username: data.username,
          uniqueid: "",
          preLocation: "home",
        })
      );
    });
  }, [items]);


  //画面reload時にデータをfetchして自分のみ表示 
  useEffect(() => {
    var flag = selector.uniqueid.flag
    if (flag === "register") {
      fetchData()
    } else {
      if (flag === "edit") {
        fetchData()
      }
    }
  }, []);


  const fetchData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      // OPTIONAL
      headers: {
        Authorization: token,
      },// OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      mode: "cors",
      queryStringParameters: {
        // OPTIONAL
        date: releaseDate.replace(/-/g, ""),
        registDate: registDate.replace(/-/g, ""),
        updateDate: updateDate.replace(/-/g, ""),
      },
    };
    API.get("searchSchedule", "/prd", myInit)
      .then((response) => {
        const records = response["data"];

        console.table(records)

        if (username === undefined) {
          setUsername("");
        }

        const m = records
          .filter((item) => {
            if (
              item["name_artist"] === undefined ||
              item["name_artist"] === null
            ) {
              item["name_artist"] = "";
            }
            return item["name_artist"]
              .toUpperCase()
              .includes(artist.toUpperCase());
          })
          .filter((item) => {
            if (
              item["name_album"] === undefined ||
              item["name_album"] === null
            ) {
              item["name_album"] = "";
            }
            return item["name_album"]
              .toUpperCase()
              .includes(album.toUpperCase());
          })
          .filter((item) => {
            if (
              item["user_registered"] === undefined ||
              item["user_registered"] === null
            ) {
              item["user_registered"] = "";
            }

            if (username === "" || selector.search.checkedflag === true) {
              return true;
            } else {
              return item["user_registered"] === username;
            }
          });

        setItems(m);

        console.table(m);
      })
      .catch((error) => {
        console.log("error");
        console.log(error.response);
      });
  };

  return (
    <Card className="App">

      {/* TOP画面ヘッダー下:begin ---- */}
      <View as="div" paddingBottom="1rem">
        <Flex direction="column" gap="1rem">
          <Heading level={5}>{"[TOPページ]"}</Heading>
          <Text fontSize="13px">Pre-Save/Pre-Add登録システムの「TOPページ」です。新規登録への遷移／登録済み情報の検索／登録済み情報の確認への遷移を行います。</Text>
        </Flex>
      </View>
      {/* TOP画面ヘッダー下:end ---- */}

      {/* 新規登録:begin ---- */}
      <View as="div" paddingBottom="1rem">

        <View as="div" backgroundColor='#ccc' padding="0.3rem">
          <Heading level={6}>■ Pre-Save/Pre-Addを提供するアルバム情報の新規登録</Heading>
        </View>

        <View as="div" padding="0.75rem">
          <Flex direction="column" gap="0.5rem">

            {/* 説明文 */}
            <Flex direction="column" gap="0rem" fontSize="13px">
              <Text>新たにPre-Save/Pre-Addを提供する場合は、『新規登録』ボタンを押してください。</Text>
            </Flex>

            <Flex width="10rem">
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to="/edit"
                onClick={() => {
                  reset();
                }}>
                <Button width="10rem">{"新規登録"}</Button>
              </Link>
            </Flex>


          </Flex>
        </View>

      </View>
      {/* 新規登録:end   ---- */}

      {/* 検索条件:begin ---- */}
      <View as="div" paddingBottom="1rem">

        <View as="div" backgroundColor='#ccc' padding="0.3rem">
          <Heading level={6}>■ 登録済みアルバム検索</Heading>
        </View>

        <View as="div" padding="0.75rem">
          <Flex direction="column" gap="1rem">

            {/* 説明文 */}
            <Flex direction="column" gap="0rem" fontSize="13px">
              <Text>
                過去に登録したアルバム情報の確認やPre-Save/Pre-Addリンクを再表示したい場合は、下記の検索条件を指定して『検索』ボタンを押してください。
              </Text>
              <View as="div" paddingInlineStart="1rem">
                <Text>
                  ・アーティスト名／アルバム名を指定した場合には、部分一致するアルバムが表示されます。
                </Text>
              </View>
            </Flex>

            {/* 区切り線 */}
            <Divider orientation="horizontal" />

            {/* 検索フォーム:begin */}
            <Flex direction="column" gap="1rem" fontSize="15px" >

              {/* 検索フォーム.リリース日:begin */}
              <Flex direction="column" gap="0rem">
                <Flex as="form" direction="row" >
                  <Text width="8rem" padding="0.5rem">
                    リリース日
                  </Text>
                  <TextField
                    label="" labelHidden={true}
                    type="date"
                    id="release_date"
                    name="release_date"
                    size="small"
                    required={true}
                    value={releaseDate}
                    onChange={handleReleaseDateChange}
                    style={{ width: "10rem" }}
                  />
                </Flex>

                <Text fontSize="13px">
                  {"　※リリース日を指定した場合には、その日以降のリリース日のアルバムのみが表示されます。"}
                </Text>
              </Flex>
              {/* 検索フォーム.リリース日:end */}


              {/* 検索フォーム.アーティスト名&最終更新日:begin */}
              <Flex as="form" direction="row" gap="2rem">

                <Flex as="form" direction="row" >
                  <Text width="8rem" padding="0.5rem">
                    アーティスト名
                  </Text>
                  <TextField
                    label="" labelHidden={true}
                    type="text"
                    id="artist"
                    name="artist"
                    size="small"
                    value={artist}
                    onChange={handleArtistChange}
                    style={{ width: "25rem" }}
                  />
                </Flex>

                <Flex as="form" direction="row" >
                  <Text width="6rem" padding="0.5rem">
                    最終更新日
                  </Text>
                  <TextField
                    label="" labelHidden={true}
                    type="date"
                    id="update_date"
                    name="update_date"
                    size="small"
                    value={updateDate}
                    onChange={handleUpdateDateChange}
                    style={{ width: "10rem" }}
                  />
                </Flex>

              </Flex>
              {/* 検索フォーム.アーティスト名&最終更新日:end */}

              {/* 検索フォーム.アルバム名&登録日:begin */}
              <Flex as="form" direction="row" gap="2rem">

                <Flex as="form" direction="row" >
                  <Text width="8rem" padding="0.5rem">
                    アルバム名
                  </Text>
                  <TextField
                    label="" labelHidden={true}
                    type="text"
                    id="album"
                    name="album"
                    size="small"
                    value={album}
                    onChange={handleAlbumChange}
                    style={{ width: "25rem" }}
                  />
                </Flex>

                <Flex direction="column" gap="0rem">
                  <Flex as="form" direction="row" >
                    <Text width="6rem" padding="0.5rem">
                      登録日
                    </Text>
                    <TextField
                      label="" labelHidden={true}
                      type="date"
                      id="regist_date"
                      name="regist_date"
                      size="small"
                      value={registDate}
                      onChange={handleRegistDateChange}
                      style={{ width: "10rem" }}
                    />
                  </Flex>
                  <Text fontSize="13px">
                    {"　※最終更新日／登録日を指定した場合には、指定された日以降に更新または登録されたアルバムが表示されます。"}
                  </Text>
                </Flex>

              </Flex>
              {/* 検索フォーム.アルバム名&登録日:end */}

              {/* 検索フォーム.他アカウントチェック:begin */}
              <Flex direction="column" gap="0rem">
                <CheckboxField
                  label="他のアカウントで登録されたアルバムも含めて検索する"
                  id="user_registered"
                  name="user_registered"
                  fontSize="15px"
                  value={username}
                  onChange={handleEditorChange}
                  checked={checkedOtherUser}
                />

                <Text fontSize="13px">
                  {"　※「他のアカウントで登録されたアルバムも含めて検索する」のチェックボックスを選択して検索した場合には、他のアカウントで登録されたアルバムも表示されます。"}
                </Text>
              </Flex>
              {/* 検索フォーム.他アカウントチェック:end */}

              <Flex direction="row">

                {/* 検索ボタン */}
                <Button
                  id="button_search"
                  name="button_search"
                  type={"submit"}
                  width="10rem"
                  onClick={() => {
                    fetchData();
                    //setUsername(username);
                  }}>
                  検索
                </Button>

                {/* 検索条件クリアボタン */}
                <Button
                  id="button_clear_search_condition"
                  name="button_clear_search_condition"
                  width="10rem"
                  disabled={isClearButtonDisabled}
                  onClick={() => {
                    window.location.reload();
                  }}>
                  検索条件クリア
                </Button>
              </Flex>

            </Flex>
            {/* 検索フォーム:end */}

          </Flex>
        </View>
      </View>
      {/* 検索条件:end   ---- */}

      {/* 検索結果:begin ---- */}
      <View as="div" paddingBottom="1rem">

        <View as="div" backgroundColor='#ccc' padding="0.3rem">
          <Heading level={6}>■ 登録済みアルバム一覧</Heading>
        </View>

        <View as="div" padding="0.75rem">
          <Flex direction="column" gap="1rem">

            {/* 説明文 */}
            <Flex direction="column" gap="0rem" fontSize="13px">
              <Text>
                検索結果一覧を表示します。
              </Text>

              <View as="div" paddingInlineStart="1rem">
                <Text>
                  ・アルバム情報の確認や、Pre-Save/Pre-Addリンクを再表示したい場合は、『内容確認』ボタンを押して確認画面に遷移してください。
                </Text>
                <Text>
                  ・確認画面から登録情報の修正へと進むことが可能です。
                </Text>
              </View>

            </Flex>

            <Flex direction="row" justifyContent="flex-end">検索結果{items.length}件</Flex>

            <Tables items={items} releaseDate={releaseDate} artist={artist} album={album} updateDate={updateDate} registDate={registDate} checkedOtherUser={checkedOtherUser} isClearButtonDisabled={isClearButtonDisabled} />

          </Flex>
        </View>

      </View>
      {/* 検索結果:end   ---- */}

    </Card>
  );
};
