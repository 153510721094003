import React from "react";
import {
  Authenticator,
  View,
  Flex,
  Image,
  Text,

} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./Home";
import { Link } from "react-router-dom";
import { Edit } from "./components/edit";
import { AppleSearch } from "./components/apple_search";
import { SpotifySearch } from "./components/spotify_search";
import { Confirm } from "./components/confirm";
import { Header, Footer } from 'antd/lib/layout/layout';
import { Layout } from 'antd';
import { setValueAction as setLoadingAction } from "./redux/loading/actions";
import { useDispatch } from "react-redux";


const App: React.VFC = () => {

  const dispatch = useDispatch();

  const handlerReduxData = () => {

    dispatch(setLoadingAction({
      loadingFlag: false,
    }))
  }



  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <BrowserRouter>
          <Layout style={{ height: "100vh" }}>

            {/* Header領域:begin ---- */}
            <Header style={{ padding: "0rem", lineHeight: "0rem", fontSize: "16px" }}>
              <View as="div" backgroundColor="#000" padding="0.5rem" >
                <Flex direction="row" justifyContent="space-between">

                  {/* logo Link to Home */}
                  <View>
                    <Link to="/" style={{ textDecoration: 'none' }} onClick={handlerReduxData}>
                      <Flex direction="row" gap="0.5rem" alignItems="center">
                        <Image alt="Home"
                          src="https://prd-pspa-s3-content.s3.ap-northeast-1.amazonaws.com/Icon/GrooveforceEngagement_logo.png"
                          height="50px"
                        />
                        <View as="div" fontWeight="600" color="#FFF">
                          {"Pre-Save Pre-Add 管理システム"}
                        </View>
                      </Flex>
                    </Link>
                  </View>

                  {/* Sign Out */}
                  <View
                    as="div"
                    padding="1.5rem"
                    textDecoration="underline"
                    fontWeight="700"
                    color="#FFF"
                    onClick={signOut}>
                    {"SignOut"}
                  </View>
                </Flex>
              </View>
            </Header>
            {/* Header領域:end ------ */}

            <Routes>
              <Route path="/" element={<Home user={user.username} />}></Route>
              <Route path="edit/:id" element={<Edit />}></Route>
              <Route path="edit" element={<Edit />}></Route>
              <Route path="appleSearch" element={<AppleSearch />}></Route>
              <Route path="spotifySearch" element={<SpotifySearch />}></Route>
              <Route path="confirm" element={<Confirm />}></Route>
            </Routes>

            {/* Footer領域:begin ---- */}
            <Footer style={{ padding: "0rem" }}>
              <View as="div" backgroundColor="#000" padding="0.5rem" >
                <Flex direction="column" alignItems="center">
                  <Text color="#FFF" fontSize="0.8rem" >
                    {"Copyright 2021 Sony Music Entertainment (Japan) Inc. All rights reserved."}
                    <br />
                    {"Copyright 2021 Sony Music Marketing United Inc. All rights reserved."}
                  </Text>
                </Flex>
              </View>
            </Footer>
            {/* Footer領域:end ------ */}

          </Layout>
        </BrowserRouter>
      )
      }
    </Authenticator >
  );
};

export default App;
