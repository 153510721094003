import * as Actions from './actions'
import initialState from '../store/initialState'

export const LoadingReducer = (state = initialState.loading, action: any) => {
    switch (action.type) {
        case Actions.SET_LOADING_VALUE:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}