export const SET_RESERVECOUNT_VALUE = 'SET_RESERVECOUNT_VALUE'
export const setValueAction = (ReserveCountState: any) => {
    return {
        type: SET_RESERVECOUNT_VALUE,
        payload: {
            apple_sum: ReserveCountState.apple_sum,
            apple_yesterday: ReserveCountState.apple_yesterday,
            spotify_sum: ReserveCountState.spotify_sum,
            spotify_yesterday: ReserveCountState.spotify_yesterday
        }
    }
}   