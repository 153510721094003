export const SET_UNIQUE_VALUE = 'SET_UNIQUE_VALUE'
export const setValueAction = (uniqueState: any) => {
    return {
        type: SET_UNIQUE_VALUE,
        payload: {
            flag: uniqueState.flag,
            uniqueid: uniqueState.uniqueid,
            username: uniqueState.username,
            preLocation: uniqueState.preLocation
        }
    }
}   