export const SET_SORT_VALUE = 'SET_SORT_VALUE'
export const setValueAction = (sortState: any) => {
    return {
        type: SET_SORT_VALUE,
        payload: {
            dateSort: sortState.dateSort,
            artistSort: sortState.artistSort,
            albumSort: sortState.albumSort,
            registDateSort: sortState.registDateSort,
            updateDateSort: sortState.updateDateSort,
        }
    }
}   